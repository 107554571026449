@import 'nib/normalize'
@import 'util/palette'
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"

vendor-prefixes = moz webkit o ms khtml

normalize-html5()
normalize-base()
normalize-groups()
normalize-table()
normalize-text()


html
	height 100%
	min-height 100%

body
	height inherit
	min-height inherit
	> header
		height 60px
	main
		height inherit
		
.btn
	color #FFF
	padding 11px 20px
	border none
	font-size 16px
	text-transform uppercase
	display block
	&.flat
		background palette('Grey', '900')
		padding 1px
		border none
		box-sizing padding-box
		> div
			background #FFF
			color palette('Grey', '900')
			padding 10px 20px
		
	&.get
		background palette('Deep Purple', '800')

.tags
	li
		padding 2px 5px
		background #ccc
		border-radius 3px
		font-size 8px
		&:not(:last-child)
			margin-right 5px
			
.row
	&.example
		// border 1px solid #f7f7f7
		[class*="col"]
			// padding-right 5px
			// padding-left 5px
			color paletteText('Purple', '800')
			&.green
				background-color palette('Green')
				
			> *
				min-height 20px
				display block
				padding-top 20px
				padding-bottom 20px
				margin 10px
				background palette('Purple', '800')

table
	thead
		tr
			background palette('Purple', '800')
			color paletteText('Purple', '800')
	&.breakpoint-visibility
		tbody
			tr
				td
					&:first-child
						text-align left
						background palette('Purple', '800')
						color paletteText('Purple', '800')
					&.success
						background palette('Green', 'A700')
						color #FFF
					&.disabled
						background palette('Grey', '200')
						color palette('Grey', '500')

article
	> h2, h3
		font-weight 300
	> h4, h5, h6
		font-weight 400!important
	> header
		padding 10px
		
code
	padding 1em 2em!important
	font-size 14px
	margin 25px 10px
	
aside
	min-height 100%
	ul
		li, li a
			color palette('Purple', '800')
			transition unset
		
		li:hover, li:hover a
			color palette('Purple', '800')
			transition unset
		
		li
			a
				padding 0.75em 1em
				display block
			&:hover
				background paletteText('Purple', '800')

.oocss
	color palette('Purple', '800')

.bcp
	background palette('Purple', '800')
